var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-item",
    {
      attrs: {
        href: _vm.href,
        rel: _vm.href && _vm.href !== "#" ? "noopener" : undefined,
        target: _vm.href && _vm.href !== "#" ? "_blank" : undefined,
        to: _vm.item.to,
        "active-class":
          "primary " + (!_vm.isDark ? "black" : "white") + "--text"
      }
    },
    [
      _vm.text
        ? _c("v-list-item-icon", {
            staticClass: "v-list-item__icon--text",
            domProps: { textContent: _vm._s(_vm.computedText) }
          })
        : _vm.item.icon
        ? _c(
            "v-list-item-icon",
            [
              _c("v-icon", { domProps: { textContent: _vm._s(_vm.item.icon) } })
            ],
            1
          )
        : _vm._e(),
      _vm.item.title || _vm.item.subtitle
        ? _c(
            "v-list-item-content",
            [
              _c("v-list-item-title", {
                domProps: { textContent: _vm._s(_vm.item.title) }
              }),
              _c("v-list-item-subtitle", {
                domProps: { textContent: _vm._s(_vm.item.subtitle) }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }