var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    _vm._b(
      { staticClass: "v-card--material pa-3", class: _vm.classes },
      "v-card",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "div",
        { staticClass: "d-flex grow flex-wrap" },
        [
          _vm.avatar
            ? _c(
                "v-avatar",
                {
                  staticClass: "mx-auto v-card--material__avatar elevation-12",
                  attrs: { size: "128", color: "grey" }
                },
                [_c("v-img", { attrs: { src: _vm.avatar } })],
                1
              )
            : _c(
                "v-sheet",
                {
                  staticClass: "text-start v-card--material__heading mb-n6",
                  class: {
                    "pa-7": !_vm.$slots.image
                  },
                  attrs: {
                    color: _vm.color,
                    "max-height": _vm.icon ? 90 : undefined,
                    width: _vm.inline || _vm.icon ? "auto" : "100%",
                    dark: ""
                  }
                },
                [
                  _vm.$slots.heading
                    ? _vm._t("heading")
                    : _vm.$slots.image
                    ? _vm._t("image")
                    : _vm.title && !_vm.icon
                    ? _c("div", {
                        staticClass: "display-1 font-weight-light",
                        domProps: { textContent: _vm._s(_vm.title) }
                      })
                    : _vm.icon
                    ? _c("v-icon", {
                        attrs: { size: "32" },
                        domProps: { textContent: _vm._s(_vm.icon) }
                      })
                    : _vm._e(),
                  _vm.text
                    ? _c("div", {
                        staticClass: "headline font-weight-thin",
                        domProps: { textContent: _vm._s(_vm.text) }
                      })
                    : _vm._e()
                ],
                2
              ),
          _vm.$slots["after-heading"]
            ? _c("div", { staticClass: "ml-6" }, [_vm._t("after-heading")], 2)
            : _vm._e(),
          _vm.hoverReveal
            ? _c(
                "v-col",
                {
                  staticClass: "text-center py-0 mt-n12",
                  attrs: { cols: "12" }
                },
                [_vm._t("reveal-actions")],
                2
              )
            : _vm.icon && _vm.title
            ? _c("div", { staticClass: "ml-4" }, [
                _c("div", {
                  staticClass: "card-title font-weight-light",
                  domProps: { textContent: _vm._s(_vm.title) }
                })
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._t("default"),
      _vm.$slots.actions
        ? [
            _c("v-divider", { staticClass: "mt-2" }),
            _c(
              "v-card-actions",
              { staticClass: "pb-0" },
              [_vm._t("actions")],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }