var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "mb-12 text-center" }, [
    _c("h1", {
      staticClass: "font-weight-light mb-2",
      staticStyle: { color: "#3c4858", "font-size": "24px" },
      domProps: { textContent: _vm._s("Vuetify " + _vm.heading) }
    }),
    _c(
      "span",
      {
        staticClass: "font-weight-light",
        staticStyle: { "font-size": "16px", color: "#3c4858" }
      },
      [
        _vm._v("\n    Please checkout the\n    "),
        _c(
          "a",
          {
            staticClass: "secondary--text",
            staticStyle: { "text-decoration": "none" },
            attrs: {
              href: "https://vuetifyjs.com/" + _vm.link,
              rel: "noopener",
              target: "_blank"
            }
          },
          [_vm._v("\n      full documentation\n    ")]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }