var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    _vm._b(
      {
        staticClass: "v-tabs--pill",
        attrs: {
          "active-class":
            _vm.color +
            " " +
            (_vm.$vuetify.theme.dark ? "black" : "white") +
            "--text",
          "hide-slider": ""
        },
        model: {
          value: _vm.internalValue,
          callback: function($$v) {
            _vm.internalValue = $$v
          },
          expression: "internalValue"
        }
      },
      "v-tabs",
      _vm.$attrs,
      false
    ),
    [_vm._t("default"), _vm._t("items")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }