var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "text-center v-card--testimony" },
    [
      _c(
        "div",
        { staticClass: "pt-6" },
        [
          _c("v-icon", { attrs: { color: "black", "x-large": "" } }, [
            _vm._v("\n      mdi-format-quote-close\n    ")
          ])
        ],
        1
      ),
      _c("v-card-text", {
        staticClass: "display-1 font-weight-light font-italic mb-3",
        domProps: { textContent: _vm._s(_vm.blurb) }
      }),
      _c("div", {
        staticClass: "display-2 font-weight-light mb-2",
        domProps: { textContent: _vm._s(_vm.author) }
      }),
      _c("div", {
        staticClass: "body-2 text-uppercase grey--text",
        domProps: { textContent: _vm._s(_vm.handle) }
      }),
      _c(
        "v-avatar",
        { attrs: { color: "grey", size: "100" } },
        [
          _c("v-img", {
            attrs: { alt: _vm.author + " Testimonial", src: _vm.avatar }
          })
        ],
        1
      ),
      _c("div")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }