var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    _vm._b(
      {
        class: _vm.classes,
        attrs: { value: _vm.value },
        on: {
          change: function($event) {
            return _vm.$emit("change", $event)
          }
        }
      },
      "v-snackbar",
      Object.assign({}, _vm.$attrs, _vm.$props, { color: "transparent" }),
      false
    ),
    [
      _c(
        "base-material-alert",
        {
          staticClass: "ma-0",
          attrs: {
            color: _vm.color,
            dismissible: _vm.dismissible,
            type: _vm.type,
            dark: ""
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }