var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-material-card",
    _vm._g(
      _vm._b(
        {
          staticClass: "v-card--material-chart",
          scopedSlots: _vm._u([
            {
              key: "heading",
              fn: function() {
                return [
                  _c("chartist", {
                    staticStyle: { "max-height": "150px" },
                    attrs: {
                      data: _vm.data,
                      "event-handlers": _vm.eventHandlers,
                      options: _vm.options,
                      ratio: _vm.ratio,
                      "responsive-options": _vm.responsiveOptions,
                      type: _vm.type
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        "base-material-card",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._t("reveal-actions", null, { slot: "reveal-actions" }),
      _vm._t("default"),
      _vm._t("actions", null, { slot: "actions" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }