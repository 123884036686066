import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
// import { sync } from 'vuex-router-sync'
import JwtService from './services/jwt.service'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import InputFacade from 'vue-input-facade'

// sync(store, router)

Vue.config.productionTip = false

const UNAUTHORIZED = 401

if (JwtService.getToken()) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${JwtService.getToken()}`
}

axios.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response) {
      const { status } = error.response
      if (status === UNAUTHORIZED) {
        console.log('401 UNAUTHORIZED')
        if (window.location.pathname !== process.env.VUE_APP_BASE_URL + '/login') {
          console.log('redirect to login from interceptor')
          window.location.href = process.env.VUE_APP_BASE_URL + '/login'
        }
      }
    }
    return Promise.reject(error)
  }
)
Vue.use(VueAxios, axios)
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.use(VueCookies)
Vue.use(InputFacade)

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
