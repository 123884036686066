import Vue from 'vue'
import Router from 'vue-router'
import JwtService from './services/jwt.service'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          path: '',
          name: 'Ваш центр',
          component: () => import('@/views/Dashboard'),
        },
        {
          path: '/clients',
          name: 'Работа с клиентами и заявками',
          view: 'ClientsList',
          component: () => import('@/views/ClientsList'),
        },
        {
          path: '/clients/:state',
          name: 'Работа с клиентами и заявками',
          view: 'ClientsList',
          props: true,
          component: () => import('@/views/ClientsList'),
        },
        {
          path: '/client/:id',
          name: 'Карточка клиента',
          props: true,
          component: () => import('@/views/Client'),
        },
        {
          path: '/actions',
          name: 'Задачи',
          view: 'ActionList',
          component: () => import('@/views/ActionList'),
        },
        {
          path: '/action/:id',
          name: 'Задачa',
          props: true,
          component: () => import('@/views/Action'),
        },
        {
          path: '/alfa-clients',
          name: 'Список клиентов в AlfaCMR',
          view: 'AlfaClientsList',
          component: () => import('@/views/AlfaClientsList'),
        },
        {
          path: '/metric/:id',
          name: 'Статистика',
          props: true,
          component: () => import('@/views/Metric'),
        },
        {
          path: '/subscriptions',
          name: 'Абонементы',
          props: true,
          component: () => import('@/views/SubscriptionsList'),
        },
        {
          path: '/subscriptions/:clientId',
          name: 'Абонементы',
          props: true,
          component: () => import('@/views/SubscriptionsList'),
        },
        {
          path: '/subscription/:id',
          name: 'Абонемент',
          props: true,
          component: () => import('@/views/Subscription'),
        },
        {
          path: '/lessons',
          name: 'Учебная сетка',
          props: true,
          component: () => import('@/views/LessonsList'),
        },
        {
          path: '/timeplan',
          name: 'Расписание',
          props: true,
          component: () => import('@/views/TimePlan'),
        },
        {
          path: '/timeplanevent', // /:id
          name: 'Событие',
          // props: true,
          component: () => import('@/views/TimePlanEvent'),
        },
        {
          path: '/newses',
          name: 'Новости',
          view: 'NewsList',
          component: () => import('@/views/NewsList'),
        },
        {
          path: '/news/:id',
          name: 'Новость',
          props: true,
          component: () => import('@/views/News'),
        },
        {
          path: '/news/create',
          name: 'Добавить новость',
          props: true,
          component: () => import('@/views/News'),
        },
        {
          path: '/templates',
          name: 'Шаблоны абонементов',
          view: 'SubscriptionTemplateList',
          component: () => import('@/views/SubscriptionTemplateList'),
        },
        {
          path: '/template/:id',
          name: 'Шаблон абонемента',
          props: true,
          component: () => import('@/views/SubscriptionTemplate'),
        },
        {
          path: '/template/create',
          name: 'Добавить шаблон абонемента',
          props: true,
          component: () => import('@/views/SubscriptionTemplate'),
        },
        {
          path: '/prices',
          name: 'Цены на обучение',
          view: 'SubscriptionTemplateList',
          component: () => import('@/views/PriceList'),
        },
        {
          path: '/price/:id',
          name: 'Цены на обучение',
          props: true,
          component: () => import('@/views/Price'),
        },
        {
          path: '/price/create',
          name: 'Добавить цены на обучение ',
          props: true,
          component: () => import('@/views/Price'),
        },
        {
          path: '/mentions',
          name: 'Отзывы',
          view: 'SubscriptionTemplateList',
          component: () => import('@/views/MentionList'),
        },
        {
          path: '/mention/:id',
          name: 'Отзыв',
          props: true,
          component: () => import('@/views/Mention'),
        },
        {
          path: '/mention/create',
          name: 'Добавить отзыв',
          props: true,
          component: () => import('@/views/Mention'),
        },
        {
          path: '/patents',
          name: 'Лицензии',
          component: () => import('@/views/PatentList'),
        },
        {
          path: '/patent/:id',
          name: 'Лицензия',
          props: true,
          component: () => import('@/views/Patent'),
        },
        {
          path: '/patent/create',
          name: 'Добавить лицензию',
          props: true,
          component: () => import('@/views/Patent'),
        },
        {
          path: '/timetables',
          name: 'Группы',
          view: 'TimetableList',
          component: () => import('@/views/TimetableList'),
        },
        {
          path: '/timetable/:id',
          name: 'Группа',
          props: true,
          component: () => import('@/views/Timetable'),
        },
        {
          path: '/timetable/create',
          name: 'Добавить группу по расписанию',
          props: true,
          component: () => import('@/views/Timetable'),
        },
        {
          path: '/schedule/:timetableId/:id',
          name: 'Расписание',
          props: true,
          component: () => import('@/views/Schedule'),
        },
        {
          path: '/schedule/:timetableId/create',
          name: 'Добавить расписание в группу',
          props: true,
          component: () => import('@/views/Schedule'),
        },
        {
          path: '/teachers',
          name: 'Преводаватели',
          component: () => import('@/views/TeacherList'),
        },
        {
          path: '/teacher/:id',
          name: 'Преводаватель',
          props: true,
          component: () => import('@/views/Teacher'),
        },
        {
          path: '/teacher/stat/:id',
          name: 'Статистика преподавателя',
          props: true,
          component: () => import('@/views/TeacherStat'),
        },
        {
          path: '/teacher/lessons/:teacherId',
          name: 'Учебная сетка преподавателя',
          props: true,
          component: () => import('@/views/LessonsList'),
        },
        {
          path: '/teacher/create',
          name: 'Добавить преподавателя',
          props: true,
          component: () => import('@/views/Teacher'),
        },

        // Pages
        {
          name: 'RTL',
          path: 'pages/rtl',
          component: () => import('@/views/dashboard/pages/Rtl'),
        },
        {
          name: 'User Profile',
          path: 'pages/user-profile',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Timeline',
          path: 'pages/timeline',
          component: () => import('@/views/dashboard/pages/Timeline'),
        },
        // Components
        {
          name: 'Buttons',
          path: 'components/buttons',
          component: () => import('@/views/dashboard/component/Buttons'),
        },
        {
          name: 'Grid System',
          path: 'components/grid-system',
          component: () => import('@/views/dashboard/component/Grid'),
        },
        {
          name: 'Tabs',
          path: 'components/tabs',
          component: () => import('@/views/dashboard/component/Tabs'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Forms
        {
          name: 'Regular Forms',
          path: 'forms/regular',
          component: () => import('@/views/dashboard/forms/RegularForms'),
        },
        {
          name: 'Extended Forms',
          path: 'forms/extended',
          component: () => import('@/views/dashboard/forms/ExtendedForms'),
        },
        {
          name: 'Validation Forms',
          path: 'forms/validation',
          component: () => import('@/views/dashboard/forms/ValidationForms'),
        },
        {
          name: 'Wizard',
          path: 'forms/wizard',
          component: () => import('@/views/dashboard/forms/Wizard'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        {
          name: 'Extended Tables',
          path: 'tables/extended-tables',
          component: () => import('@/views/dashboard/tables/ExtendedTables'),
        },
        {
          name: 'Data Tabels',
          path: 'tables/data-tables',
          component: () => import('@/views/dashboard/tables/DataTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        {
          name: 'Full Screen Map',
          path: 'maps/full-screen-map',
          component: () => import('@/views/dashboard/maps/FullScreenMap'),
        },
        // Root level
        {
          name: 'Widgets',
          path: 'widgets',
          component: () => import('@/views/dashboard/Widgets'),
        },
        {
          name: 'Charts',
          path: 'charts',
          component: () => import('@/views/dashboard/Charts'),
        },
        {
          name: 'Calendar',
          path: 'calendar',
          component: () => import('@/views/dashboard/Calendar'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Вход',
          path: 'login',
          component: () => import('@/views/Login'),
        },
      ],
    },
    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Lock',
          path: 'lock',
          component: () => import('@/views/pages/Lock'),
        },
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/pages/Login'),
        },
        {
          name: 'Pricing',
          path: 'pricing',
          component: () => import('@/views/pages/Pricing'),
        },
        {
          name: 'Register',
          path: 'register',
          component: () => import('@/views/pages/Register'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = JwtService.isAuthenticated()

  if (authRequired && !loggedIn) {
    console.log('invalid login')
    if (window.location.pathname !== process.env.VUE_APP_BASE_URL + '/login') {
      console.log('redirect to login from router')
      next({
        path: process.env.VUE_APP_BASE_URL + '/login',
        query: { returnUrl: to.path },
      })
    }
  }
  next()
})

export default router
