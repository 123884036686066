var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "v-card--wizard",
      attrs: { elevation: "12", "max-width": "700" }
    },
    [
      _c(
        "v-card-title",
        { staticClass: "justify-center display-2 font-weight-light pt-5" },
        [_vm._v("\n    Build your profile\n  ")]
      ),
      _c(
        "div",
        {
          staticClass: "text-center display-1 grey--text font-weight-light mb-6"
        },
        [_vm._v("\n    This information will let us know more about you.\n  ")]
      ),
      _c(
        "v-tabs",
        {
          ref: "tabs",
          attrs: {
            "background-color": "green lighten-5",
            color: "white",
            grow: "",
            "slider-size": "50"
          },
          model: {
            value: _vm.internalValue,
            callback: function($$v) {
              _vm.internalValue = $$v
            },
            expression: "internalValue"
          }
        },
        [
          _c("v-tabs-slider", {
            staticClass: "mt-1",
            attrs: { color: "success" }
          }),
          _vm._l(_vm.items, function(item, i) {
            return _c(
              "v-tab",
              {
                key: i,
                attrs: {
                  ripple: false,
                  disabled: !_vm.availableSteps.includes(i)
                }
              },
              [_vm._v("\n      " + _vm._s(item) + "\n    ")]
            )
          })
        ],
        2
      ),
      _c("div", { staticClass: "my-6" }),
      _c(
        "v-card-text",
        [
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.internalValue,
                callback: function($$v) {
                  _vm.internalValue = $$v
                },
                expression: "internalValue"
              }
            },
            [_vm._t("default")],
            2
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pb-4 pa-4" },
        [
          _c(
            "v-btn",
            {
              staticClass: "white--text",
              attrs: {
                disabled: _vm.internalValue === 0,
                color: "grey darken-2",
                "min-width": "125"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("click:prev")
                }
              }
            },
            [_vm._v("\n      Previous\n    ")]
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                disabled: !_vm.availableSteps.includes(_vm.internalValue + 1),
                color: "success",
                "min-width": "100"
              },
              on: {
                click: function($event) {
                  return _vm.$emit("click:next")
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.internalValue === _vm.items.length - 1
                      ? "Finish"
                      : "Next"
                  ) +
                  "\n    "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }