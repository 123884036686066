var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-list-group",
    {
      attrs: {
        group: _vm.group,
        "prepend-icon": _vm.item.icon,
        "sub-group": _vm.subGroup,
        "append-icon": "mdi-menu-down",
        color:
          _vm.barColor !== "rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)"
            ? "white"
            : "grey darken-1"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function() {
            return [
              _vm.text
                ? _c("v-list-item-icon", {
                    staticClass: "v-list-item__icon--text",
                    domProps: { textContent: _vm._s(_vm.computedText) }
                  })
                : _vm.item.avatar
                ? _c(
                    "v-list-item-avatar",
                    {
                      staticClass: "align-self-center",
                      attrs: { color: "grey" }
                    },
                    [
                      _c("v-img", {
                        attrs: { src: require("@/assets/avatar-poli.png") }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", {
                    domProps: { textContent: _vm._s(_vm.item.title) }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._l(_vm.children, function(child, i) {
        return [
          child.children
            ? _c("base-item-sub-group", {
                key: "sub-group-" + i,
                attrs: { item: child }
              })
            : _c("base-item", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !child.hide,
                    expression: "!child.hide"
                  }
                ],
                key: "item-" + i,
                attrs: { item: child, text: "" }
              })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }