import axios from 'axios'

const ID_USER_KEY = 'id_user'
const COOKIE_ID_USER_KEY = 'cus'

export const Admin = 'Admin'
export const OfficeAdmin = 'OfficeAdmin'
export const OfficeOwner = 'OfficeOwner'
export const HomeTeacherAdmin = 'HomeTeacherAdmin'

export const getToken = () => {
  let user = JSON.parse(window.localStorage.getItem(ID_USER_KEY))
  if (user) {
    return user.token
  }
  let cookieUser = window.$cookies.get(COOKIE_ID_USER_KEY)
  if (cookieUser) {
    saveUser(cookieUser)
    // window.$cookies.remove(COOKIE_ID_USER_KEY)
    return cookieUser.token
  }
}

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem(ID_USER_KEY))
}

export const saveUser = user => {
  window.localStorage.setItem(ID_USER_KEY, JSON.stringify(user))
  window.$cookies.set(COOKIE_ID_USER_KEY, user)
  axios.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`
}

export const destroyUser = () => {
  window.localStorage.removeItem(ID_USER_KEY)
  window.$cookies.remove(COOKIE_ID_USER_KEY)
  axios.defaults.headers.common['Authorization'] = null
}

export const isAuthenticated = () => {
  return !!getToken() && Array.isArray(getUser().roles)
}

export const hasRole = (role) => {
  let user = getUser()
  if (user && user.roles && user.roles.length > 0) {
    return user.roles.indexOf(role) > -1
  }
  return false
}

export const hasRoles = (roles) => {
  let user = getUser()
  if (user && user.roles && user.roles.length > 0) {
    const found = user.roles.some(r => roles.includes(r))
    return found
  }
  return false
}

export default { getToken, saveUser, destroyUser, isAuthenticated, hasRole }
