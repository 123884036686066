var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    _vm._b(
      {
        attrs: { transition: _vm.transition, "offset-y": "" },
        scopedSlots: _vm._u(
          [
            {
              key: "activator",
              fn: function(ref) {
                var attrs = ref.attrs
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            color: _vm.color,
                            default: "",
                            "min-width": "200",
                            rounded: ""
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._t("default"),
                      _c("v-icon", [
                        _vm._v(
                          "\n        mdi-" +
                            _vm._s(_vm.value ? "menu-up" : "menu-down") +
                            "\n      "
                        )
                      ])
                    ],
                    2
                  )
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.value,
          callback: function($$v) {
            _vm.value = $$v
          },
          expression: "value"
        }
      },
      "v-menu",
      _vm.$attrs,
      false
    ),
    [
      _c(
        "v-sheet",
        [
          _c(
            "v-list",
            { attrs: { dense: "" } },
            _vm._l(_vm.items, function(item, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  on: {
                    click: function($event) {
                      return _vm.$("click:action-" + item.id)
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", {
                        domProps: { textContent: _vm._s(item.text) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }